import { default as indexmEGrlXaulvMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue?macro=true";
import { default as CategoryypR3p2J7oUMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue?macro=true";
import { default as CaseStudyjcfH5yfOOlMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue?macro=true";
import { default as CaseStudyListnCvoHbrg0NMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue?macro=true";
import { default as index2PLJnKB70FMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue?macro=true";
import { default as CheckoutL4iWvbTWhcMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue?macro=true";
import { default as CheckoutAgainstbvHoTEl2DnMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue?macro=true";
import { default as indexWdvZA9ReRaMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue?macro=true";
import { default as EthernetCableGuider88p1kFVbnMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue?macro=true";
import { default as ServiceZlTPuR3CvCMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue?macro=true";
import { default as PicosSoftwareON8sJolHVAMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue?macro=true";
import { default as ContactSalesMailVFesmlxezOMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue?macro=true";
import { default as HowToBuyOoPDa9yE3xMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue?macro=true";
import { default as ProductSupport6Kpk4M6PAXMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue?macro=true";
import { default as IndexgmJLTIBQsxMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue?macro=true";
import { default as TechnicalDocumentsqlFAdlLsfpMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue?macro=true";
import { default as SpecialsPfWi1kZJsvMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue?macro=true";
import { default as BlogjB5YVHTPnjMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue?macro=true";
import { default as BlogDetailimZO4ZwQSkMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue?macro=true";
import { default as BlogPreviewNUbcTX5EdnMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue?macro=true";
import { default as CreateNewCasew1wMUjyMRqMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue?macro=true";
import { default as SiteMapFHFQQJ8EsNMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue?macro=true";
import { default as SolutionDemoTestkINTrSMLNpMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue?macro=true";
import { default as RMAChecklist4xWo1DXrITMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue?macro=true";
import { default as GlossaryWPiT5lY0CoMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue?macro=true";
import { default as GlossaryDetailnZxGCth92FMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue?macro=true";
import { default as GlossaryPreviewhzcdFIqbjBMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue?macro=true";
import { default as TestServiceHCMcbOzpoxMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue?macro=true";
import { default as SolutionTestRgbTGySS7dMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue?macro=true";
import { default as ReliabilityTestw358cFh6YMMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue?macro=true";
import { default as CompatibilityTestlAEw6Z1gvOMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue?macro=true";
import { default as PerformanceTestBBEFmXhG0nMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue?macro=true";
import { default as FreeDesignSolutionyU77XpQCOTMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue?macro=true";
import { default as indexdUOYfEoudyMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue?macro=true";
import { default as indexZDz6zWcSJbMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue?macro=true";
import { default as Video38KC3eKmHyMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue?macro=true";
import { default as VideoDetail9XvhFTP1KfMeta } from "/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue?macro=true";
export default [
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___au",
    path: indexmEGrlXaulvMeta?.path ?? "/au",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___de",
    path: indexmEGrlXaulvMeta?.path ?? "/de",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___de-en",
    path: indexmEGrlXaulvMeta?.path ?? "/de-en",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___en",
    path: indexmEGrlXaulvMeta?.path ?? "/",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___es",
    path: indexmEGrlXaulvMeta?.path ?? "/es",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___mx",
    path: indexmEGrlXaulvMeta?.path ?? "/mx",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___fr",
    path: indexmEGrlXaulvMeta?.path ?? "/fr",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___it",
    path: indexmEGrlXaulvMeta?.path ?? "/it",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___jp",
    path: indexmEGrlXaulvMeta?.path ?? "/jp",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___ru",
    path: indexmEGrlXaulvMeta?.path ?? "/ru",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___sg",
    path: indexmEGrlXaulvMeta?.path ?? "/sg",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexmEGrlXaulvMeta?.name ?? "home___uk",
    path: indexmEGrlXaulvMeta?.path ?? "/uk",
    meta: { ...(indexmEGrlXaulvMeta || {}), ...{"pageGroup":"Home Page"} },
    alias: indexmEGrlXaulvMeta?.alias || [],
    redirect: indexmEGrlXaulvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___au",
    path: CategoryypR3p2J7oUMeta?.path ?? "/au/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___de",
    path: CategoryypR3p2J7oUMeta?.path ?? "/de/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___de-en",
    path: CategoryypR3p2J7oUMeta?.path ?? "/de-en/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___en",
    path: CategoryypR3p2J7oUMeta?.path ?? "/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___es",
    path: CategoryypR3p2J7oUMeta?.path ?? "/es/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___mx",
    path: CategoryypR3p2J7oUMeta?.path ?? "/mx/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___fr",
    path: CategoryypR3p2J7oUMeta?.path ?? "/fr/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___it",
    path: CategoryypR3p2J7oUMeta?.path ?? "/it/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___jp",
    path: CategoryypR3p2J7oUMeta?.path ?? "/jp/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___ru",
    path: CategoryypR3p2J7oUMeta?.path ?? "/ru/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___sg",
    path: CategoryypR3p2J7oUMeta?.path ?? "/sg/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CategoryypR3p2J7oUMeta?.name ?? "category___uk",
    path: CategoryypR3p2J7oUMeta?.path ?? "/uk/c/:id",
    meta: { ...(CategoryypR3p2J7oUMeta || {}), ...{"pageGroup":""} },
    alias: CategoryypR3p2J7oUMeta?.alias || [],
    redirect: CategoryypR3p2J7oUMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Category/Category.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___au",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/au/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___de",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/de/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___de-en",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/de-en/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___en",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___es",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/es/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___mx",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/mx/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___fr",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/fr/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___it",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/it/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___jp",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/jp/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___ru",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/ru/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___sg",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/sg/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyjcfH5yfOOlMeta?.name ?? "case-study___uk",
    path: CaseStudyjcfH5yfOOlMeta?.path ?? "/uk/case-study/:id.html",
    meta: { ...(CaseStudyjcfH5yfOOlMeta || {}), ...{"pageGroup":"Case Study Page"} },
    alias: CaseStudyjcfH5yfOOlMeta?.alias || [],
    redirect: CaseStudyjcfH5yfOOlMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudy/CaseStudy.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___au",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/au/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___de",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/de/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___de-en",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/de-en/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___en",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___es",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/es/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___mx",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/mx/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___fr",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/fr/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___it",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/it/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___jp",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/jp/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___ru",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/ru/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___sg",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/sg/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: CaseStudyListnCvoHbrg0NMeta?.name ?? "case-study-list___uk",
    path: CaseStudyListnCvoHbrg0NMeta?.path ?? "/uk/case-study.html",
    meta: { ...(CaseStudyListnCvoHbrg0NMeta || {}), ...{"pageGroup":"Case Study Page Detail"} },
    alias: CaseStudyListnCvoHbrg0NMeta?.alias || [],
    redirect: CaseStudyListnCvoHbrg0NMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/CaseStudyList/CaseStudyList.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___au",
    path: index2PLJnKB70FMeta?.path ?? "/au/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___de",
    path: index2PLJnKB70FMeta?.path ?? "/de/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___de-en",
    path: index2PLJnKB70FMeta?.path ?? "/de-en/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___en",
    path: index2PLJnKB70FMeta?.path ?? "/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___es",
    path: index2PLJnKB70FMeta?.path ?? "/es/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___mx",
    path: index2PLJnKB70FMeta?.path ?? "/mx/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___fr",
    path: index2PLJnKB70FMeta?.path ?? "/fr/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___it",
    path: index2PLJnKB70FMeta?.path ?? "/it/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___jp",
    path: index2PLJnKB70FMeta?.path ?? "/jp/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___ru",
    path: index2PLJnKB70FMeta?.path ?? "/ru/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___sg",
    path: index2PLJnKB70FMeta?.path ?? "/sg/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: index2PLJnKB70FMeta?.name ?? "share_url___uk",
    path: index2PLJnKB70FMeta?.path ?? "/uk/share_url/:code/:id",
    meta: { ...(index2PLJnKB70FMeta || {}), ...{"pageGroup":"Share Url Page"} },
    alias: index2PLJnKB70FMeta?.alias || [],
    redirect: index2PLJnKB70FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TransferPage/index.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___au",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/au/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___de",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/de/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___de-en",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/de-en/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___en",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___es",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/es/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___mx",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/mx/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___fr",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/fr/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___it",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/it/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___jp",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/jp/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___ru",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/ru/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___sg",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/sg/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutL4iWvbTWhcMeta?.name ?? "checkout___uk",
    path: CheckoutL4iWvbTWhcMeta?.path ?? "/uk/checkout",
    meta: { ...(CheckoutL4iWvbTWhcMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutL4iWvbTWhcMeta?.alias || [],
    redirect: CheckoutL4iWvbTWhcMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/Checkout.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___au",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/au/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___de",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/de/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___de-en",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/de-en/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___en",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___es",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/es/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___mx",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/mx/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___fr",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/fr/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___it",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/it/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___jp",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/jp/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___ru",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/ru/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___sg",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/sg/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: CheckoutAgainstbvHoTEl2DnMeta?.name ?? "checkout-against___uk",
    path: CheckoutAgainstbvHoTEl2DnMeta?.path ?? "/uk/checkout-against",
    meta: { ...(CheckoutAgainstbvHoTEl2DnMeta || {}), ...{"pageGroup":"Checkout Page"} },
    alias: CheckoutAgainstbvHoTEl2DnMeta?.alias || [],
    redirect: CheckoutAgainstbvHoTEl2DnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Checkout/CheckoutAgainst.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___au",
    path: indexWdvZA9ReRaMeta?.path ?? "/au/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___de",
    path: indexWdvZA9ReRaMeta?.path ?? "/de/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___de-en",
    path: indexWdvZA9ReRaMeta?.path ?? "/de-en/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___en",
    path: indexWdvZA9ReRaMeta?.path ?? "/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___es",
    path: indexWdvZA9ReRaMeta?.path ?? "/es/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___mx",
    path: indexWdvZA9ReRaMeta?.path ?? "/mx/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___fr",
    path: indexWdvZA9ReRaMeta?.path ?? "/fr/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___it",
    path: indexWdvZA9ReRaMeta?.path ?? "/it/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___jp",
    path: indexWdvZA9ReRaMeta?.path ?? "/jp/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___ru",
    path: indexWdvZA9ReRaMeta?.path ?? "/ru/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___sg",
    path: indexWdvZA9ReRaMeta?.path ?? "/sg/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: indexWdvZA9ReRaMeta?.name ?? "tool___uk",
    path: indexWdvZA9ReRaMeta?.path ?? "/uk/tool/:id",
    meta: { ...(indexWdvZA9ReRaMeta || {}), ...{"pageGroup":"Customer Service_Tool\t"} },
    alias: indexWdvZA9ReRaMeta?.alias || [],
    redirect: indexWdvZA9ReRaMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Tools/index.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___au",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/au/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___de",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/de/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___de-en",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/de-en/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___en",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___es",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/es/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___mx",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/mx/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___fr",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/fr/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___it",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/it/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___jp",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/jp/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___ru",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/ru/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___sg",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/sg/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: EthernetCableGuider88p1kFVbnMeta?.name ?? "ethernet-cable-guide___uk",
    path: EthernetCableGuider88p1kFVbnMeta?.path ?? "/uk/ethernet-cable-buying-guide.html",
    meta: { ...(EthernetCableGuider88p1kFVbnMeta || {}), ...{"pageGroup":"Customer Service_Buying Guide"} },
    alias: EthernetCableGuider88p1kFVbnMeta?.alias || [],
    redirect: EthernetCableGuider88p1kFVbnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/EthernetCableGuide/EthernetCableGuide.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___au",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/au/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___de",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/de/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___de-en",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/de-en/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___en",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___es",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/es/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___mx",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/mx/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___fr",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/fr/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___it",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/it/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___jp",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/jp/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___ru",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/ru/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___sg",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/sg/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: ServiceZlTPuR3CvCMeta?.name ?? "customer-services___uk",
    path: ServiceZlTPuR3CvCMeta?.path ?? "/uk/fscare-customer-services.html",
    meta: { ...(ServiceZlTPuR3CvCMeta || {}), ...{"pageGroup":"Customer Service_Customer Services"} },
    alias: ServiceZlTPuR3CvCMeta?.alias || [],
    redirect: ServiceZlTPuR3CvCMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Service/Service.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___au",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/au/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___de",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/de/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___de-en",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/de-en/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___en",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___es",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/es/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___mx",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/mx/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___fr",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/fr/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___it",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/it/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___jp",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/jp/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___ru",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/ru/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___sg",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/sg/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: PicosSoftwareON8sJolHVAMeta?.name ?? "software-customized___uk",
    path: PicosSoftwareON8sJolHVAMeta?.path ?? "/uk/picos-software-customized.html",
    meta: { ...(PicosSoftwareON8sJolHVAMeta || {}), ...{"pageGroup":"Customer Service_Software Customized"} },
    alias: PicosSoftwareON8sJolHVAMeta?.alias || [],
    redirect: PicosSoftwareON8sJolHVAMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PicosSoftware/PicosSoftware.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___au",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/au/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___de",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/de/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___de-en",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/de-en/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___en",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___es",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/es/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___mx",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/mx/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___fr",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/fr/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___it",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/it/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___jp",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/jp/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___ru",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/ru/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___sg",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/sg/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: ContactSalesMailVFesmlxezOMeta?.name ?? "contact_sales_mail___uk",
    path: ContactSalesMailVFesmlxezOMeta?.path ?? "/uk/contact_sales_mail.html",
    meta: { ...(ContactSalesMailVFesmlxezOMeta || {}), ...{"pageGroup":"Customer Service_Contact Sales Mail"} },
    alias: ContactSalesMailVFesmlxezOMeta?.alias || [],
    redirect: ContactSalesMailVFesmlxezOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ContactSalesMail/ContactSalesMail.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___au",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/au/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___de",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/de/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___de-en",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/de-en/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___en",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___es",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/es/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___mx",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/mx/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___fr",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/fr/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___it",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/it/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___jp",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/jp/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___ru",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/ru/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___sg",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/sg/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: HowToBuyOoPDa9yE3xMeta?.name ?? "how-to-buy___uk",
    path: HowToBuyOoPDa9yE3xMeta?.path ?? "/uk/how-to-buy.html",
    meta: { ...(HowToBuyOoPDa9yE3xMeta || {}), ...{"pageGroup":"How To Buy Page"} },
    alias: HowToBuyOoPDa9yE3xMeta?.alias || [],
    redirect: HowToBuyOoPDa9yE3xMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/HowToBuy/HowToBuy.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___au",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/au/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___de",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/de/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___de-en",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/de-en/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___en",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___es",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/es/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___mx",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/mx/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___fr",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/fr/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___it",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/it/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___jp",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/jp/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___ru",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/ru/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___sg",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/sg/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: ProductSupport6Kpk4M6PAXMeta?.name ?? "products-support___uk",
    path: ProductSupport6Kpk4M6PAXMeta?.path ?? "/uk/products_support.html",
    meta: { ...(ProductSupport6Kpk4M6PAXMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: ProductSupport6Kpk4M6PAXMeta?.alias || [],
    redirect: ProductSupport6Kpk4M6PAXMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/ProductSupport.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___au",
    path: IndexgmJLTIBQsxMeta?.path ?? "/au/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___de",
    path: IndexgmJLTIBQsxMeta?.path ?? "/de/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___de-en",
    path: IndexgmJLTIBQsxMeta?.path ?? "/de-en/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___en",
    path: IndexgmJLTIBQsxMeta?.path ?? "/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___es",
    path: IndexgmJLTIBQsxMeta?.path ?? "/es/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___mx",
    path: IndexgmJLTIBQsxMeta?.path ?? "/mx/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___fr",
    path: IndexgmJLTIBQsxMeta?.path ?? "/fr/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___it",
    path: IndexgmJLTIBQsxMeta?.path ?? "/it/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___jp",
    path: IndexgmJLTIBQsxMeta?.path ?? "/jp/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___ru",
    path: IndexgmJLTIBQsxMeta?.path ?? "/ru/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___sg",
    path: IndexgmJLTIBQsxMeta?.path ?? "/sg/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: IndexgmJLTIBQsxMeta?.name ?? "preview_sheet___uk",
    path: IndexgmJLTIBQsxMeta?.path ?? "/uk/products_support/preview_sheet.html",
    meta: { ...(IndexgmJLTIBQsxMeta || {}), ...{"pageGroup":""} },
    alias: IndexgmJLTIBQsxMeta?.alias || [],
    redirect: IndexgmJLTIBQsxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/ProductSupport/components/PreviewCmsSheet/Index.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___au",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/au/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___de",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/de/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___de-en",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/de-en/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___en",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___es",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/es/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___mx",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/mx/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___fr",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/fr/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___it",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/it/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___jp",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/jp/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___ru",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/ru/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___sg",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/sg/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    name: TechnicalDocumentsqlFAdlLsfpMeta?.name ?? "technical_documents___uk",
    path: TechnicalDocumentsqlFAdlLsfpMeta?.path ?? "/uk/technical_documents.html",
    meta: { ...(TechnicalDocumentsqlFAdlLsfpMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: TechnicalDocumentsqlFAdlLsfpMeta?.alias || [],
    redirect: TechnicalDocumentsqlFAdlLsfpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/TechnicalDocuments/TechnicalDocuments.vue").then(m => m.default || m)
  },
  {
    path: "/technical_documents/search.html",
    name: "technical_documents_search",
    meta: {"pageGroup":"Technical Documents Search Page"}
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___au",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/au/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___de",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___de-en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de-en/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___es",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/es/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___mx",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/mx/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___fr",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/fr/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___it",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/it/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___jp",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/jp/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___ru",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/ru/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___sg",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/sg/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "specials___uk",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/uk/specials/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Customer Service_Technical Documents"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___au",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/au/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___de",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___de-en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/de-en/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___en",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___es",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/es/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___mx",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/mx/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___fr",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/fr/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___it",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/it/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___jp",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/jp/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___ru",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/ru/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___sg",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/sg/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: SpecialsPfWi1kZJsvMeta?.name ?? "supports___uk",
    path: SpecialsPfWi1kZJsvMeta?.path ?? "/uk/support/:id.html",
    meta: { ...(SpecialsPfWi1kZJsvMeta || {}), ...{"pageGroup":"Specials Page"} },
    alias: SpecialsPfWi1kZJsvMeta?.alias || [],
    redirect: SpecialsPfWi1kZJsvMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/Specials.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___au",
    path: BlogjB5YVHTPnjMeta?.path ?? "/au/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___de",
    path: BlogjB5YVHTPnjMeta?.path ?? "/de/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___de-en",
    path: BlogjB5YVHTPnjMeta?.path ?? "/de-en/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___en",
    path: BlogjB5YVHTPnjMeta?.path ?? "/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___es",
    path: BlogjB5YVHTPnjMeta?.path ?? "/es/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___mx",
    path: BlogjB5YVHTPnjMeta?.path ?? "/mx/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___fr",
    path: BlogjB5YVHTPnjMeta?.path ?? "/fr/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___it",
    path: BlogjB5YVHTPnjMeta?.path ?? "/it/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___jp",
    path: BlogjB5YVHTPnjMeta?.path ?? "/jp/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___ru",
    path: BlogjB5YVHTPnjMeta?.path ?? "/ru/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___sg",
    path: BlogjB5YVHTPnjMeta?.path ?? "/sg/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogjB5YVHTPnjMeta?.name ?? "blog___uk",
    path: BlogjB5YVHTPnjMeta?.path ?? "/uk/blog.html",
    meta: { ...(BlogjB5YVHTPnjMeta || {}), ...{"pageGroup":"Blog Page"} },
    alias: BlogjB5YVHTPnjMeta?.alias || [],
    redirect: BlogjB5YVHTPnjMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Blog/Blog.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___au",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/au/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___de",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/de/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___de-en",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/de-en/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___en",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___es",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/es/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___mx",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/mx/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___fr",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/fr/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___it",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/it/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___jp",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/jp/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___ru",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/ru/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___sg",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/sg/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogDetailimZO4ZwQSkMeta?.name ?? "blog_detail___uk",
    path: BlogDetailimZO4ZwQSkMeta?.path ?? "/uk/blog/:id.html",
    meta: { ...(BlogDetailimZO4ZwQSkMeta || {}), ...{"pageGroup":"Blog Page Detail"} },
    alias: BlogDetailimZO4ZwQSkMeta?.alias || [],
    redirect: BlogDetailimZO4ZwQSkMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogDetail.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___au",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/au/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___de",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/de/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___de-en",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/de-en/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___en",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___es",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/es/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___mx",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/mx/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___fr",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/fr/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___it",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/it/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___jp",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/jp/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___ru",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/ru/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___sg",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/sg/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: BlogPreviewNUbcTX5EdnMeta?.name ?? "blog_preview___uk",
    path: BlogPreviewNUbcTX5EdnMeta?.path ?? "/uk/blog_preview.html",
    meta: { ...(BlogPreviewNUbcTX5EdnMeta || {}), ...{"pageGroup":""} },
    alias: BlogPreviewNUbcTX5EdnMeta?.alias || [],
    redirect: BlogPreviewNUbcTX5EdnMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/BlogDetail/BlogPreview.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___au",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/au/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___de",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/de/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___de-en",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/de-en/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___en",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___es",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/es/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___mx",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/mx/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___fr",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/fr/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___it",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/it/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___jp",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/jp/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___ru",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/ru/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___sg",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/sg/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: CreateNewCasew1wMUjyMRqMeta?.name ?? "create_new_support___uk",
    path: CreateNewCasew1wMUjyMRqMeta?.path ?? "/uk/create-new-support",
    meta: { ...(CreateNewCasew1wMUjyMRqMeta || {}), ...{"pageGroup":"Create New Support Page"} },
    alias: CreateNewCasew1wMUjyMRqMeta?.alias || [],
    redirect: CreateNewCasew1wMUjyMRqMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CreateNewCase/CreateNewCase.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___au",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/au/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___de",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/de/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___de-en",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/de-en/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___en",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___es",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/es/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___mx",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/mx/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___fr",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/fr/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___it",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/it/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___jp",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/jp/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___ru",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/ru/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___sg",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/sg/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SiteMapFHFQQJ8EsNMeta?.name ?? "site-map___uk",
    path: SiteMapFHFQQJ8EsNMeta?.path ?? "/uk/site_map.html",
    meta: SiteMapFHFQQJ8EsNMeta || {},
    alias: SiteMapFHFQQJ8EsNMeta?.alias || [],
    redirect: SiteMapFHFQQJ8EsNMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/SiteMap/SiteMap.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___au",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/au/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___de",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/de/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___de-en",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/de-en/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___en",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___es",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/es/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___mx",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/mx/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___fr",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/fr/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___it",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/it/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___jp",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/jp/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___ru",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/ru/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___sg",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/sg/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "solution_demo_test___uk",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/uk/solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Solution Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___au",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/au/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___de",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/de/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___de-en",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/de-en/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___en",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___es",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/es/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___mx",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/mx/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___fr",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/fr/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___it",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/it/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___jp",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/jp/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___ru",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/ru/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___sg",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/sg/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionDemoTestkINTrSMLNpMeta?.name ?? "data_center_solution_demo_test___uk",
    path: SolutionDemoTestkINTrSMLNpMeta?.path ?? "/uk/data-center-solution-demo-test.html",
    meta: { ...(SolutionDemoTestkINTrSMLNpMeta || {}), ...{"pageGroup":"Customer Service_Data Center Demo Test"} },
    alias: SolutionDemoTestkINTrSMLNpMeta?.alias || [],
    redirect: SolutionDemoTestkINTrSMLNpMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionDemoTest/SolutionDemoTest.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___au",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/au/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___de",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/de/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___de-en",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/de-en/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___en",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___es",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/es/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___mx",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/mx/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___fr",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/fr/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___it",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/it/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___jp",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/jp/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___ru",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/ru/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___sg",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/sg/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: RMAChecklist4xWo1DXrITMeta?.name ?? "rma_checklist___uk",
    path: RMAChecklist4xWo1DXrITMeta?.path ?? "/uk/service/rma_checklist.html",
    meta: { ...(RMAChecklist4xWo1DXrITMeta || {}), ...{"pageGroup":"Personal Hub_Order History Page_Popup Tech Support Application"} },
    alias: RMAChecklist4xWo1DXrITMeta?.alias || [],
    redirect: RMAChecklist4xWo1DXrITMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/RMAChecklist/RMAChecklist.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___au",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/au/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___de",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/de/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___de-en",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/de-en/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___en",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___es",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/es/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___mx",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/mx/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___fr",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/fr/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___it",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/it/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___jp",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/jp/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___ru",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/ru/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___sg",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/sg/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryWPiT5lY0CoMeta?.name ?? "glossary___uk",
    path: GlossaryWPiT5lY0CoMeta?.path ?? "/uk/glossary.html",
    meta: { ...(GlossaryWPiT5lY0CoMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryWPiT5lY0CoMeta?.alias || [],
    redirect: GlossaryWPiT5lY0CoMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Glossary/Glossary.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___au",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/au/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___de",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/de/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___de-en",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/de-en/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___en",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___es",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/es/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___mx",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/mx/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___fr",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/fr/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___it",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/it/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___jp",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/jp/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___ru",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/ru/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___sg",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/sg/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryDetailnZxGCth92FMeta?.name ?? "glossary_detail___uk",
    path: GlossaryDetailnZxGCth92FMeta?.path ?? "/uk/glossary/:id.html",
    meta: { ...(GlossaryDetailnZxGCth92FMeta || {}), ...{"pageGroup":"Customer Service_Glossary"} },
    alias: GlossaryDetailnZxGCth92FMeta?.alias || [],
    redirect: GlossaryDetailnZxGCth92FMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryDetail.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___au",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/au/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___de",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/de/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___de-en",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/de-en/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___en",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___es",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/es/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___mx",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/mx/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___fr",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/fr/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___it",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/it/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___jp",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/jp/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___ru",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/ru/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___sg",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/sg/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: GlossaryPreviewhzcdFIqbjBMeta?.name ?? "glossary_preview___uk",
    path: GlossaryPreviewhzcdFIqbjBMeta?.path ?? "/uk/glossary_preview.html",
    meta: { ...(GlossaryPreviewhzcdFIqbjBMeta || {}), ...{"pageGroup":""} },
    alias: GlossaryPreviewhzcdFIqbjBMeta?.alias || [],
    redirect: GlossaryPreviewhzcdFIqbjBMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/GlossaryDetail/GlossaryPreview.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___au",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/au/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___de",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/de/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___de-en",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/de-en/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___en",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___es",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/es/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___mx",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/mx/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___fr",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/fr/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___it",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/it/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___jp",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/jp/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___ru",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/ru/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___sg",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/sg/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: TestServiceHCMcbOzpoxMeta?.name ?? "test_service___uk",
    path: TestServiceHCMcbOzpoxMeta?.path ?? "/uk/test_service.html",
    meta: { ...(TestServiceHCMcbOzpoxMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: TestServiceHCMcbOzpoxMeta?.alias || [],
    redirect: TestServiceHCMcbOzpoxMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/TestService/TestService.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___au",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/au/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___de",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/de/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___de-en",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/de-en/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___en",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___es",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/es/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___mx",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/mx/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___fr",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/fr/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___it",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/it/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___jp",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/jp/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___ru",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/ru/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___sg",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/sg/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: SolutionTestRgbTGySS7dMeta?.name ?? "solution_test___uk",
    path: SolutionTestRgbTGySS7dMeta?.path ?? "/uk/solution_test.html",
    meta: { ...(SolutionTestRgbTGySS7dMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: SolutionTestRgbTGySS7dMeta?.alias || [],
    redirect: SolutionTestRgbTGySS7dMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/SolutionTest/SolutionTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___au",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/au/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___de",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/de/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___de-en",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/de-en/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___en",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___es",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/es/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___mx",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/mx/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___fr",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/fr/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___it",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/it/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___jp",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/jp/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___ru",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/ru/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___sg",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/sg/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: ReliabilityTestw358cFh6YMMeta?.name ?? "reliability_test___uk",
    path: ReliabilityTestw358cFh6YMMeta?.path ?? "/uk/reliability_test.html",
    meta: { ...(ReliabilityTestw358cFh6YMMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: ReliabilityTestw358cFh6YMMeta?.alias || [],
    redirect: ReliabilityTestw358cFh6YMMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/ReliabilityTest/ReliabilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___au",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/au/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___de",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/de/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___de-en",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/de-en/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___en",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___es",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/es/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___mx",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/mx/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___fr",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/fr/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___it",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/it/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___jp",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/jp/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___ru",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/ru/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___sg",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/sg/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: CompatibilityTestlAEw6Z1gvOMeta?.name ?? "compatibility_test___uk",
    path: CompatibilityTestlAEw6Z1gvOMeta?.path ?? "/uk/compatibility_test.html",
    meta: { ...(CompatibilityTestlAEw6Z1gvOMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: CompatibilityTestlAEw6Z1gvOMeta?.alias || [],
    redirect: CompatibilityTestlAEw6Z1gvOMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/CompatibilityTest/CompatibilityTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___au",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/au/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___de",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/de/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___de-en",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/de-en/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___en",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___es",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/es/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___mx",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/mx/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___fr",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/fr/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___it",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/it/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___jp",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/jp/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___ru",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/ru/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___sg",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/sg/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: PerformanceTestBBEFmXhG0nMeta?.name ?? "performance_test___uk",
    path: PerformanceTestBBEFmXhG0nMeta?.path ?? "/uk/performance_test.html",
    meta: { ...(PerformanceTestBBEFmXhG0nMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: PerformanceTestBBEFmXhG0nMeta?.alias || [],
    redirect: PerformanceTestBBEFmXhG0nMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Supports/PerformanceTest/PerformanceTest.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___au",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/au/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___de",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/de/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___de-en",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/de-en/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___en",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___es",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/es/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___mx",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/mx/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___fr",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/fr/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___it",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/it/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___jp",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/jp/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___ru",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/ru/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___sg",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/sg/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: FreeDesignSolutionyU77XpQCOTMeta?.name ?? "free_design_solution___uk",
    path: FreeDesignSolutionyU77XpQCOTMeta?.path ?? "/uk/free_design_solution.html",
    meta: { ...(FreeDesignSolutionyU77XpQCOTMeta || {}), ...{"pageGroup":"pageGroup"} },
    alias: FreeDesignSolutionyU77XpQCOTMeta?.alias || [],
    redirect: FreeDesignSolutionyU77XpQCOTMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FreeDesignSolution/FreeDesignSolution.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___au",
    path: indexdUOYfEoudyMeta?.path ?? "/au/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___de",
    path: indexdUOYfEoudyMeta?.path ?? "/de/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___de-en",
    path: indexdUOYfEoudyMeta?.path ?? "/de-en/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___en",
    path: indexdUOYfEoudyMeta?.path ?? "/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___es",
    path: indexdUOYfEoudyMeta?.path ?? "/es/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___mx",
    path: indexdUOYfEoudyMeta?.path ?? "/mx/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___fr",
    path: indexdUOYfEoudyMeta?.path ?? "/fr/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___it",
    path: indexdUOYfEoudyMeta?.path ?? "/it/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___jp",
    path: indexdUOYfEoudyMeta?.path ?? "/jp/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___ru",
    path: indexdUOYfEoudyMeta?.path ?? "/ru/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___sg",
    path: indexdUOYfEoudyMeta?.path ?? "/sg/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexdUOYfEoudyMeta?.name ?? "fiber_performance_calculator___uk",
    path: indexdUOYfEoudyMeta?.path ?? "/uk/fiber_performance_calculator.html",
    meta: indexdUOYfEoudyMeta || {},
    alias: indexdUOYfEoudyMeta?.alias || [],
    redirect: indexdUOYfEoudyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/FiberPerformanceCalculator/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___au",
    path: indexZDz6zWcSJbMeta?.path ?? "/au/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___de",
    path: indexZDz6zWcSJbMeta?.path ?? "/de/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___de-en",
    path: indexZDz6zWcSJbMeta?.path ?? "/de-en/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___en",
    path: indexZDz6zWcSJbMeta?.path ?? "/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___es",
    path: indexZDz6zWcSJbMeta?.path ?? "/es/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___mx",
    path: indexZDz6zWcSJbMeta?.path ?? "/mx/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___fr",
    path: indexZDz6zWcSJbMeta?.path ?? "/fr/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___it",
    path: indexZDz6zWcSJbMeta?.path ?? "/it/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___jp",
    path: indexZDz6zWcSJbMeta?.path ?? "/jp/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___ru",
    path: indexZDz6zWcSJbMeta?.path ?? "/ru/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___sg",
    path: indexZDz6zWcSJbMeta?.path ?? "/sg/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: indexZDz6zWcSJbMeta?.name ?? "calculator_pdf___uk",
    path: indexZDz6zWcSJbMeta?.path ?? "/uk/calculator_pdf.html",
    meta: indexZDz6zWcSJbMeta || {},
    alias: indexZDz6zWcSJbMeta?.alias || [],
    redirect: indexZDz6zWcSJbMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Specials/CalculatorPdf/index.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___au",
    path: Video38KC3eKmHyMeta?.path ?? "/au/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___de",
    path: Video38KC3eKmHyMeta?.path ?? "/de/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___de-en",
    path: Video38KC3eKmHyMeta?.path ?? "/de-en/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___en",
    path: Video38KC3eKmHyMeta?.path ?? "/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___es",
    path: Video38KC3eKmHyMeta?.path ?? "/es/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___mx",
    path: Video38KC3eKmHyMeta?.path ?? "/mx/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___fr",
    path: Video38KC3eKmHyMeta?.path ?? "/fr/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___it",
    path: Video38KC3eKmHyMeta?.path ?? "/it/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___jp",
    path: Video38KC3eKmHyMeta?.path ?? "/jp/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___ru",
    path: Video38KC3eKmHyMeta?.path ?? "/ru/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___sg",
    path: Video38KC3eKmHyMeta?.path ?? "/sg/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: Video38KC3eKmHyMeta?.name ?? "media___uk",
    path: Video38KC3eKmHyMeta?.path ?? "/uk/media.html",
    meta: Video38KC3eKmHyMeta || {},
    alias: Video38KC3eKmHyMeta?.alias || [],
    redirect: Video38KC3eKmHyMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/Video/Video.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___au",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/au/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___de",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/de/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___de-en",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/de-en/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___en",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___es",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/es/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___mx",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/mx/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___fr",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/fr/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___it",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/it/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___jp",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/jp/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___ru",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/ru/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___sg",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/sg/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  },
  {
    name: VideoDetail9XvhFTP1KfMeta?.name ?? "media_detail___uk",
    path: VideoDetail9XvhFTP1KfMeta?.path ?? "/uk/media/:id.html",
    meta: VideoDetail9XvhFTP1KfMeta || {},
    alias: VideoDetail9XvhFTP1KfMeta?.alias || [],
    redirect: VideoDetail9XvhFTP1KfMeta?.redirect,
    component: () => import("/home/jenkins/agent/workspace/platform-static front-category-prod/pages/VideoDetail/VideoDetail.vue").then(m => m.default || m)
  }
]