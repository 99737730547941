import type { IMetaData } from "./types/metaTypes";

export const useMeatStore = defineStore("meta", {
	state: (): IMetaData => {
		return {
			domain: "",
			title: "",
			description: "",
			default_title: "",
			default_description: "",
			locale: "",
			site_name: "",
			commonMeta: [],
			verifyMeta: [],
			robotsMeta: [],
			ogMeta: [],
			twitterMeta: [],
			commonLink: [],
			link: [],
			langLink: []
		};
	},
	getters: {
		metaData: state => {
			return [...state.commonMeta, ...state.verifyMeta, ...state.ogMeta, ...state.twitterMeta];
		},
		linkData: state => {
			const route = useRoute();
			// if (/\/case-study\/[^/]*\.html$/.test(route?.path)) {
			// 	return [...state.commonLink, ...state.link];
			// } else {
			return [...state.commonLink, ...state.link, ...state.langLink];
			// }
		}
	},
	actions: {}
});
