export default defineNuxtPlugin(_nuxtApp => {
	const deviceStore = useDeviceStore();
	const userStore = useUserStore();
	const headerStore = useHeaderStore();

	const websiteStore = useWebsiteStore();
	const preWebsiteInfo: any = useCookies("preWebsiteInfo");
	const fs_websiteinfo = useCookies("fs_websiteinfo");
	const _fs_pvid = useCookies("fs_pvid");
	const { device, isChromeLighthouse } = storeToRefs(deviceStore);
	const { website } = storeToRefs(websiteStore);

	if (process.client) {
		if (website?.value !== "cn" && !isChromeLighthouse.value) {
			function getCookie(name: string) {
				const cookies = document.cookie.split(";");
				for (let i = 0; i < cookies.length; i++) {
					const cookie = cookies[i].trim();
					if (cookie.startsWith(name + "=")) {
						return cookie.substring(name.length + 1);
					}
				}
				return null;
			}
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				window.dataLayer.push(arguments);
			}
			window.gtag = gtag;
			const cookievalue = getCookie("cookieconsent_dismissed");
			// 默认意见
			gtag("consent", "default", {
				region: [
					"AT",
					"BE",
					"BG",
					"CY",
					"CZ",
					"DE",
					"DK",
					"EE",
					"ES",
					"FI",
					"FR",
					"GR",
					"HR",
					"HU",
					"IE",
					"IS",
					"IT",
					"LI",
					"LT",
					"LU",
					"LV",
					"MT",
					"NL",
					"NO",
					"PL",
					"PT",
					"RO",
					"SE",
					"SI",
					"SK"
				],
				ad_storage: "denied",
				ad_user_data: "denied",
				ad_personalization: "denied",
				analytics_storage: "denied",
				wait_for_update: 500
			});
			// 过往意见
			if (cookievalue && cookievalue.length != 0) {
				if (cookievalue == "yes") {
					gtag("consent", "update", {
						region: [
							"AT",
							"BE",
							"BG",
							"CY",
							"CZ",
							"DE",
							"DK",
							"EE",
							"ES",
							"FI",
							"FR",
							"GR",
							"HR",
							"HU",
							"IE",
							"IS",
							"IT",
							"LI",
							"LT",
							"LU",
							"LV",
							"MT",
							"NL",
							"NO",
							"PL",
							"PT",
							"RO",
							"SE",
							"SI",
							"SK"
						],
						ad_storage: "granted",
						ad_user_data: "granted",
						ad_personalization: "granted",
						analytics_storage: "granted"
					});
				} else {
					gtag("consent", "update", {
						region: [
							"AT",
							"BE",
							"BG",
							"CY",
							"CZ",
							"DE",
							"DK",
							"EE",
							"ES",
							"FI",
							"FR",
							"GR",
							"HR",
							"HU",
							"IE",
							"IS",
							"IT",
							"LI",
							"LT",
							"LU",
							"LV",
							"MT",
							"NL",
							"NO",
							"PL",
							"PT",
							"RO",
							"SE",
							"SI",
							"SK"
						],
						ad_storage: "denied",
						ad_user_data: "denied",
						ad_personalization: "denied",
						analytics_storage: "denied"
					});
				}
			}
			(function (w: Window, d: Document, s: string, l: string, i: string) {
				(w as any)[l] = (w as any)[l] || [];
				(w as any)[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
				const f = d.getElementsByTagName(s)[0];
				const j = d.createElement(s) as HTMLScriptElement;
				const dl = l != "dataLayer" ? "&l=" + l : "";
				j.defer = true;
				j.async = true;
				j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
				if (f.parentNode) {
					f.parentNode.insertBefore(j, f);
				}
			})(window, document, "script", "dataLayer", "GTM-PBGKN3");
			const noscript = document.createElement("noscript");
			const iframe = document.createElement("iframe");
			iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-PBGKN3";
			iframe.width = "0";
			iframe.height = "0";
			iframe.style.display = "none";
			iframe.style.visibility = "hidden";
			noscript.appendChild(iframe);
			document.body.appendChild(noscript);
		}
	}
});
